<template>
  <div class="page-main">
    <!-- 表格操作条 -->
    <vxe-toolbar ref="refToolbar" size="mini" export print custom :loading="loading">
      <template #buttons>
        <el-button type="success" size="mini" @click="itemAdd">新增地址</el-button>
      </template>
      <template #tools>
        <el-input
          v-model="keyword"
          style="width: 180px"
          size="mini"
          placeholder="请输入关键字"
          clearable
        />
        <el-button class="m-l-10" type="primary" size="mini" @click="searchListData">
          搜索
        </el-button>
      </template>
    </vxe-toolbar>

    <!-- 表格 -->
    <vxe-grid
      ref="refTable"
      size="mini"
      border
      highlight-hover-row
      :loading="loading"
      :data="tableDataMain"
      :columns="tableColumn"
      :max-height="this.$util.getViewHeight() - 150"
      :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
      :checkbox-config="{ labelField: 'id', range: true }"
    >
      <!-- slot_header -->
      <template #header_autocomplete="{ column }">
        <c-table-header
          :title="column.title"
          :field="column.field"
          :url="headerFilterUrl"
          @change="headerFilterChange"
        />
      </template>

      <!-- slot_default -->
      <template #default_operate="{ row }">
        <template>
          <vxe-button icon="el-icon-edit-outline" title="编辑" circle @click="itemEdit(row)" />
        </template>
        <vxe-button icon="el-icon-delete" title="删除" circle @click="itemDelete(row)" />
      </template>

      <!-- slot_pager -->
      <template #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>

    <!-- 地址增改弹窗 -->
    <PopupAddress
      v-if="isShowPopupAddress"
      :show.sync="isShowPopupAddress"
      :data.sync="itemObj"
      @success="getListData"
    />
  </div>
</template>

<script>
import PopupAddress from './popup-address'
export default {
  components: { PopupAddress },
  data() {
    return {
      loading: true,

      keyword: '',
      filterData: {},
      sortData: {},
      headerFilterUrl: '/admin/gcvip/address/searchHeard',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      tableColumn: [
        {
          field: 'account_name',
          title: '所属账户',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'link_man',
          title: '联系人',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'link_phone',
          title: '联系手机',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'address',
          title: '地址',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'company_name',
          title: '公司名',
          slots: { header: 'header_autocomplete' }
        },
        {
          field: 'tag',
          title: '标签',
          slots: { header: 'header_autocomplete' }
        },
        { title: '操作', width: 160, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page
      },
      isShowPopupAddress: false,
      itemDefault: {
        id: '',
        account_id: '',
        link_man: '',
        link_phone: '',
        address: '',
        company_name: '',
        tag: ''
      },
      itemObj: {}
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联
      this.$refs.refTable.connect(this.$refs.refToolbar)
      this.initItemObj()
      this.getListData()
    })
  },
  methods: {
    // 初始化itemObj
    initItemObj() {
      this.itemObj = this.$util.deepCopy(this.itemDefault)
    },
    // 头部筛选更新
    headerFilterChange(obj) {
      this.filterData[obj.field] = obj.value
      this.searchListData()
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取数据列表
    getListData() {
      this.loading = true
      this.$api({
        method: 'post',
        url: '/admin/gcvip/address/search',
        data: {
          keyword: this.keyword,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          sortData: this.sortData,
          filterData: this.filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 项-新增
    itemAdd() {
      this.isShowPopupAddress = true
      this.initItemObj()
    },
    // 项-编辑
    itemEdit(row) {
      this.isShowPopupAddress = true
      this.itemObj = this.$util.deepCopy(row)
    },
    // 项-删除
    itemDelete(row) {
      this.$confirm('您确认要删除该数据?', '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/address/delete',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
}
</style>
